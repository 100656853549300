import { carousel, container, reviewBody, reviewImage, reviewName, reviewWrapper, wrapper } from './index.module.scss'

import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

const Reviews = ({ reviews=[] }) => {
  return (
    <div className={wrapper}>
      <div className={container}>
        <div className={carousel}>
          {reviews.map(review => (
            <div key={review.id} className={reviewWrapper}>
              <div className={reviewImage}>
                <GatsbyImage image={review?.image?.childImageSharp?.gatsbyImageData} alt={review?.name}/>
                <div className={reviewName}>- {review?.name}</div>
              </div>
              <div className={reviewBody}>{review?.body}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Reviews
