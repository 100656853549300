import { graphql, useStaticQuery } from "gatsby"

import Layout from '../layouts'
import PageHeader from '../components/page/header'
import React from 'react'
import Reviews from '../components/reviews'
import Seo from '../components/seo'
import Text from '../components/text'

const Therapy = () => {
  const { pageHeaderImage, reviews } = useStaticQuery(
    graphql`
      {
        pageHeaderImage: imageSharp(resize: {originalName: {eq: "personal-training-hero.jpg"}}) {
          gatsbyImageData(
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1000
          )
        }

        reviews: allCmsReview {
          edges {
            node {
              id
              body
              name: reviewerName
              image {
                childImageSharp {
                  gatsbyImageData(
                    outputPixelDensities: [0.25, 0.5, 1, 2, 3]
                    quality: 75
                    transformOptions: {
      								cropFocus: CENTER,
                      fit: COVER
                    }
                    width: 367
                  )
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo
        title='Bewertungen'
        currentUrl='/bewertungen'
      />
      <PageHeader
        headline='Bewertungen'
        copy='Ich habe mittlerweile seit über 10 Jahren professionelle Erfahrung in der Verbindung von funktionellem Training mit angewandter Neurologie und Krafttraining.'
        image={pageHeaderImage}
      />
      <Text text={`Seit über 10 Jahren unterstütze ich Menschen mit ihren Zielen und Problemen.

__INTELLIGENTES, DYNAMISCHES COACHING MIT HERZBLUT UND LEIDENSCHAFT__
      `}/>
    <Reviews reviews={reviews.edges.map(r => r.node)}/>
    </Layout>
  )
}

export default Therapy
